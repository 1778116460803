<template>
  <div>
    <div class="title">账号设置</div>
    <div class="content">
      <el-form
        ref="ruleForm"
        label-position="right"
        label-width="90px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="账号/手机号">
          <el-input
            disabled
            style="width: 638px"
            v-model="formLabelAlign.phone"
          ></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            style="width: 638px"
            v-model="formLabelAlign.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="pwd">
          <el-input
            type="password"
            style="width: 638px"
            v-model="formLabelAlign.pwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input
            style="width: 435px"
            v-model="formLabelAlign.verify"
          ></el-input>
          <el-button style="width: 202px" @click="getCode" :disabled="flag">{{
            val
          }}</el-button>
        </el-form-item>
        <el-form-item label=" ">
          <div class="flex">
            <div class="btn" @click="Submit('ruleForm')">提交</div>
            <div class="btn1">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (
        this.formLabelAlign.password == "" ||
        !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(
          this.formLabelAlign.password
        )
      ) {
        callback(
          new Error(
            "请输入由数字和字母组成，并且要同时含有数字和字母，且长度要在8-16位之间的密码"
          )
        );
      } else {
        callback();
      }
    };
    var validatepwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
      } else if (this.formLabelAlign.password != this.formLabelAlign.pwd) {
        callback(new Error("二次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        password: [{ validator: validatePass, trigger: "change" }],
        pwd: [{ validator: validatepwd, trigger: "change" }],
      },
      val: "发送手机验证码",
      flag: false,
      time: null,

      code: "",
      formLabelAlign: {
        username: "",
        verify: "",
        phone: "",
        password: "",
        pwd: "",
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    this.formLabelAlign.phone = this.userInfo.username;
  },
  methods: {
    ...mapActions(["getVerify", "getChangePassword"]),
    //修改密码
    Submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formLabelAlign.verify == "") {
            this.$message.error("请输入验证码");
            return;
          }
          if (this.code != this.formLabelAlign.verify) {
            this.$message.error("请输入正确的验证码");
            return;
          }
          this.formLabelAlign.username = this.formLabelAlign.phone;
          this.getChangePassword({
            username: this.formLabelAlign.username,
            verify: this.formLabelAlign.verify,
            phone: this.formLabelAlign.phone,
            password: this.formLabelAlign.password,
          }).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "success",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //获取验证码
    getCode() {
      if (!/^1[3456789]\d{9}$/.test(this.formLabelAlign.phone)) {
        this.$message({
          type: "cancel",
          message: "请输入正确的手机号",
        });
        return;
      }
      this.formLabelAlign.verify = "";
      this.getVerify({
        kindof: 1,
        phone: this.formLabelAlign.phone,
      }).then((res) => {
        this.$message({
          type: res.code == 200 ? "success" : "cancel",
          message: res.msg,
        });
        if (res.code == 200) {
          this.code = res.data;
          this.val = 60;
          this.getTime();
        }
      });
    },
    //倒计时
    getTime() {
      let that = this;
      this.val = 60 + "s";
      this.time = setInterval(() => {
        this.val = this.val.replace("s", "");
        this.val = +this.val;
        --this.val;
        this.val = this.val + "s";
        if (this.val == "0s") {
          this.val = "发送手机验证码";
          clearInterval(this.time);
        }
      }, 1000);
    },
  },
};
</script>

<style lang='scss' scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.content {
  margin-top: 14px;
  padding: 70px 40px 500px 40px;
  box-sizing: border-box;
  background-color: #fff;
  .btn {
    cursor: pointer;
    width: 163px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #137cfb;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .btn1 {
    cursor: pointer;

    margin-left: 15px;
    width: 163px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #edf5fe;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    border: 1px solid #d8e1ea;
    color: #7c7c7c;
  }
}
</style>